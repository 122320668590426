
// Fonts

// Variables
@import "variables";

// Bootstrap
@import '~bootstrap/scss/bootstrap';



body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}